import React from 'react';
import Layout from '../../../components/Layout';
import { Container, Row, Column } from '../../../components/Grid';
import Section from '../../../components/Section';
import { Link } from 'gatsby';
import Image from '../../../components/Image';

const BraedenBenedict = () => (
  <Layout
    title="Alumni Spotlight - Braeden Benedict"
    className="alumni-center bios"
  >
    {/* Alumni Profile */}
    <Section className="alumni">
      <Container>
        {/* Breadcrumbs */}
        <ul className="breadcrumbs no-hero">
          <li className="breadcrumb">
            <Link to="../../overview">Overview</Link>
          </li>
          <li className="breadcrumb">
            <Link to="../../alumni-spotlight">Alumni Spotlight</Link>
          </li>
          <li className="breadcrumb active">Braeden Benedict</li>
        </ul>
        <Row>
          <Column size={7} offset={1} className="alumni__profile-left pr-2">
            <h2 className="alumni__profile-greeting">Meet Alumni:</h2>
            <h1 className="alumni__profile-title">Braeden Benedict</h1>
            <h3 className="alumni__profile-type">
              2011 Young Scientist Challenge Winner
            </h3>
            <h4 className="alumni__profile-project">
              Braeden is currently a medical student at Washington University in
              St. Louis, with an interest in neurosurgery. After participating
              in the 2011 3M Young Scientist Challenge, Braeden continued
              working on his innovation and received his bachelor’s degree in
              electrical engineering.
            </h4>
            <h2 className="alumni__profile-question">
              What have you been up to since participating in the 3M Young
              Scientist Challenge?!
            </h2>
            <p>
              Quite a bit, it's been 12 years! Briefly, I kept doing concussion
              and other brain-related research in high school. I got an
              undergraduate degree in electrical engineering and got a master's
              degree in bioengineering before realizing I wanted to go to
              medical school. During my graduate studies, I worked on millimeter
              sized neural recording and stimulation implants which use
              ultrasound for power and communication. Between then and beginning
              medical school at Washington University in St. Louis, I worked for
              a year at Lawrence Berkeley National Lab where I helped make
              electronics for projects ranging from neural recording implants to
              gamma ray detectors. This past year, I also got married!
            </p>
            <h2 className="alumni__profile-question">
              You’re in medical school now. Can you tell us more about your
              journey there and what you’re studying?
            </h2>
            <p>
              It was not a direct journey, since I've always been interested in
              many things and am not good at making decisions. I couldn't decide
              for many years whether I should go to graduate school, or medical
              school, or both. I decided to go to graduate school for
              bioengineering before eventually changing my mind, dropping out
              (with my master's degree), and starting medical school. I
              certainly wish I had figured this all out sooner, but I'll get
              there eventually. Right now, I'm still learning the basics of
              medicine and how to be a physician, but I'm very interested in
              neurosurgery. I'm currently doing research on nerve compression,
              improved spinal fusion through electrical stimulation, and using
              daily preoperative surveys and activity tracking to predict
              outcomes of spine surgery.
            </p>
            <h2 className="alumni__profile-question">
              You were the 2011 3M YSC winner. Did your experience participating
              in the 3M Young Scientist Challenge affect your academic/career
              paths? If yes, how?
            </h2>
            <p>
              I would likely not be in medical school today if it weren't for
              [3M]YSC. The project I did for [3M]YSC was what got me interested
              in concussions, the brain, and medicine in general. In the course
              of doing my project, I spoke with physicians and other medical
              professionals, and these were some of my first exposures to the
              medical field.
            </p>
            <h2 className="alumni__profile-question">
              What advice do you have for a student who is considering entering
              the 3M Young Scientist Challenge?
            </h2>
            <p>
              Identifying and trying to solve a real-world problem is a daunting
              task. Be on the lookout for problems you see in your everyday life
              and don't be discouraged when you realize you're not the first one
              to try to solve that problem. I remember some of my initial ideas
              from back in 2011 when I was applying: cameras on the sides of
              cars/trucks to reduce blind spots (already invented), pressure
              sensors built into tires to monitor pressure to improve gas
              mileage (already invented), new urinal shapes to reduce splashing
              (gross). Even my eventual idea to put sensors on helmets to
              monitor for concussive impacts had already been done in a
              different form. That's ok, science and engineering are done by
              building on the work of others. The point is, you have to keep
              asking questions and realize that it's REALLY HARD to come up with
              a 100% brand new idea that's also a good idea! It's unlikely to
              happen on your first try, so don't give up.
            </p>
            <h2 className="alumni__profile-question">
              What is your favorite element on the periodic table and why?
            </h2>
            <p>
              Platinum. It's a valuable metal, and when I proposed to my wife it
              was with a ring made from platinum. It is also biocompatible and
              makes a good electrode material for implants, which is how I'm
              currently using it in my research.
            </p>
          </Column>
          <Column size={4}>
            <div className="global__profile-box box-shadow">
              <div className="global__profile-image">
                <Image filename="Benedict-Braeden-2011-Finalist-Quote.png" />
              </div>
              <div className="global__profile-content">
                <h4 className="quote">
                  “Being a finalist was an amazing opportunity that opened up
                  many doors for me and changed my life by starting my interest
                  in the brain and medicine. ”
                </h4>
                <h3>Braeden Benedict</h3>
                <p>2011 Winner</p>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Related Alumni Profiles */}
    <Section className="related-profiles">
      <Container>
        <Row>
          <Column fullwidth>
            <h2>Related Alumni</h2>
          </Column>
        </Row>
        <Row>
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="Katie-Lampo-Alumni-Card.jpg" />
              </div>
              <div className="profile-card__content">
                <span>2017 Finalist</span>
                <h3>Kathryn Lampo</h3>
                <p>
                  Kathryn is currently a sophomore at Columbia University
                  pursuing a B.S. in Mechanical Engineering with a minor in
                  Applied Physics. She’s also interested in aerospace
                  engineering!
                </p>
                <Link to="../katie-lampo" className="text-20 text-bold">
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="Kaien-Yang-Alumni-Card.jpg" />
              </div>
              <div className="profile-card__content">
                <span>2016 Finalist</span>
                <h3>Kaien Yang</h3>
                <p>
                  Kaien is a sophomore at Standford University majoring in
                  Computer Science with an AI track. He works with robots and
                  machine learning, and plays for Stanford’s club tennis team.
                </p>
                <Link to="../kaien-yang" className="text-20 text-bold">
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="Nicholas-LaJoie-Alumni-Card.jpg" />
              </div>
              <div className="profile-card__content">
                <span>2009 Finalist</span>
                <h3>Nicholas LaJoie</h3>
                <p>
                  Nicholas studied Computer Engineering in college and is
                  currently pursuing a career in technology. He plays the drums
                  and has worked in filmmaking.
                </p>
                <Link to="../nicholas-lajoie" className="text-20 text-bold">
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default BraedenBenedict;
